import React from "react";

export const Footer = ({ contractsFetch }) => {
    
    return (
        <div className="w-full h-full bg-[#070707] flex items-center justify-center z-[1] pb-12 sm:pb-10 mt-[120px] sm:mt-[10px]">
            <div className="max-w-[1500px] w-full flex flex-col items-center min-h-[100px] z-[1] w-full px-[108px] sm:p-5">
                <div className="flex flex-col items-center space-y-3 pb-5 sm:pb-10 pt-5">
                    {!!contractsFetch?.frgxBSC && (
                        <div className="sm:pb-2.5 sm:border-b sm:border-white-200 text-sm text-white-700 font-semibold flex items-center justify-center w-full space-x-2  sm:flex-col sm:space-x-0 sm:space-y-2.5">
                            <span className="notranslate text-sm text-white-700 sm:relative font-semibold">FRGX Contract (BSC)</span>
                            <a href={`https://bscscan.com/address/${contractsFetch?.frgxBSC}`} target="_blank" className="notranslate text-sm text-white-700 sm:relative font-semibold" rel="noreferrer">{contractsFetch?.frgxBSC}</a>
                            <span className="sm:hidden">&nbsp;|&nbsp;</span>
                            <a href={`https://www.dextools.io/app/en/bnb/pair-explorer/0x55f303a94b8b085917459ae7e0b252b0e20b5add?t=1724334498034`} target="_blank" className="notranslate text-sm text-white-700 sm:relative font-semibold" rel="noreferrer">DEXTools</a>
                        </div>
                    )}

                    {!!contractsFetch?.frgxTRC && (
                        <div className="flex flex-col w-full space-y-2.5">    
                            <div className="text-sm text-white-700 font-semibold flex items-center justify-center w-full space-x-2 sm:flex-col sm:space-x-0 sm:space-y-2.5">
                                <span className="notranslate text-sm text-white-700 sm:relative font-semibold">FRGX Contract (TRC-20)</span>
                                <a href={`https://tronscan.org/#/token20/${contractsFetch?.frgxTRC}`} target="_blank" className="notranslate text-sm text-white-700 sm:relative font-semibold" rel="noreferrer">{contractsFetch?.frgxTRC}</a>
                                <span className="sm:hidden">&nbsp;|&nbsp;</span>
                                <a href={`https://www.dextools.io/app/en/tron/pair-explorer/TNfqupATp3SBL1N7mwGHLP4sVN9WXMyPu2?t=1724330880435`} target="_blank" className="notranslate text-sm text-white-700 sm:relative font-semibold" rel="noreferrer">DEXTools</a>
                            </div>
                            <div className="mx-auto text-center text-white pt-2.5 lg:max-w-[90%]"><span>Don't forget your sunscreen when you go to the beach - </span><a className="underline" href="https://sunfrgx.fun" target="_blank">https://sunfrgx.fun</a></div>
                        </div>
                    )}
                </div>
              
                <div className="flex sm:flex-col items-center justify-between w-full space-y-2 pb-5">
                    <div  className="sm:order-2 flex flex-col lg:flex-col-reverse flex-1 h-[25px] sm:w-[105px] sm:h-[15px]" >
                        <img alt="frgxGrayLogo" className='h-full lg:pb-5' src="/icons/frgxGrayLogo.svg"/>
                        <div className="flex space-x-5 py-5 lg:pt-0 lg:justify-center">
                            <a href="https://t.me/frgxfinance" target="_blank" rel="noreferrer">
                                <img className="w-6 h-6" src="/icons/social/telegram.svg" alt="" />
                            </a>
                            <a href="https://twitter.com/FRGXfinance" target="_blank" rel="noreferrer">
                                <img className="w-6 h-6" src="/icons/social/twitter.svg" alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="sm:order-3 flex-1 flex items-center justify-center text-sm text-[#565656] sm:relative sm:text-[13px]">Copyright © FRGX.finance 2024</div>
                    <div className="sm:order-1 sm:pb-5 flex-1 flex justify-end items-center sm:justify-start text-[#565656] space-x-1.5 hover:underline">
                        <img src="/icons/mail.svg" className="w-5 h-5" alt="" />
                        <a href="mailto:info@frgx.finance">info@frgx.finance</a>
                    </div>
                </div>
            </div>
        </div>
    )
}